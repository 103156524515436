import AttachesBlock from 'components/AttachesBlock/AttachesBlock'
import HeaderBlock from 'components/HeaderBlock/HeaderBlock'
import IframelyBlock from 'components/IframelyBlock/IframelyBlock'
import ImageBlock from 'components/ImageBlock/ImageBlock'
import LinkToolBlock from 'components/LinkToolBlock/LinkToolBlock'
import OnlineMeetingBlock from 'components/OnlineMeetingBlock/OnlineMeetingBlock'

export const editorJSRenderer = {
  image: ImageBlock,
  linkTool: LinkToolBlock,
  videoCall: OnlineMeetingBlock,
  attaches: AttachesBlock,
  header: HeaderBlock,
  iframely: IframelyBlock,
}
