import Link from 'next/link'

type LinkBlockProps = {
  url: string
  title?: string
  desc?: string
  imageUrl?: string
}
const LinkBlock = ({ url, title, desc, imageUrl }: LinkBlockProps) => {
  // remove url protocol
  const displayUrl = url.substr(url.indexOf('://') + 3)
  return (
    <Link href={url}>
      <a target='_blank' className='!no-underline' data-testid='anchor'>
        <div className='border border-mischka rounded-lg p-4 my-5 sm:flex'>
          {title && (
            <div
              className='flex-1 text-gray-700 font-semibold mb-3 sm:hidden leading-snug'
              data-testid='title'
            >
              {title}
            </div>
          )}

          {imageUrl && (
            <div className='sm:w-40 sm:mr-4 flex-none'>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                className='rounded-lg w-full h-full object-cover !my-0'
                alt={title}
                src={imageUrl}
                data-testid='image'
              />
            </div>
          )}

          <div className='min-w-0'>
            {title && (
              <div
                className='flex-1 text-gray-700 font-semibold hidden sm:block leading-snug'
                data-testid='title-md'
              >
                {title}
              </div>
            )}
            {desc && (
              <p
                className='text-sm leading-7 !text-gray-700'
                data-testid='desc'
              >
                {desc}
              </p>
            )}
            <div
              className='text-xs !text-gray-500 whitespace-nowrap overflow-hidden overflow-ellipsis'
              data-testid='displayUrl'
            >
              {displayUrl}
            </div>
          </div>
        </div>
      </a>
    </Link>
  )
}

export default LinkBlock
