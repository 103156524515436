import React from 'react'
import ReactHtmlParser from 'react-html-parser'

export interface CustomHeaderBlockData {
  text: string
  level: number
  anchor?: string
}

const HeaderBlock = ({
  data,
  className = 'font-heading text-3xl font-medium text-tuna',
}: {
  data: CustomHeaderBlockData
  className?: string
}) => {
  const props: {
    [s: string]: string
  } = {}

  if (className) {
    props.className = className
  }

  if (data.anchor) {
    props.id = data.anchor
  }

  const Tag = `h${data.level || 1}` as keyof JSX.IntrinsicElements
  return <Tag {...props}>{data?.text && ReactHtmlParser(data.text)}</Tag>
}

export default HeaderBlock
