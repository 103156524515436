import Blocks from 'editorjs-blocks-react-renderer'
import { editorJSRenderer } from 'lib/editorJSRenderer'
import React from 'react'
import { BlockType } from 'types'

const EditorJsBlocks = ({
  data,
}: { data: BlockType; id?: string | number }) => {
  return data?.blocks?.length > 0 ? (
    <div className='prose max-w-none'>
      <Blocks data={data} renderers={editorJSRenderer} />
    </div>
  ) : null
}

export default EditorJsBlocks
