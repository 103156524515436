import Image from 'next/image'

const decodeHTMLEntities = (text: string) => {
  const parser = new DOMParser()
  const decodedString = parser.parseFromString(text, 'text/html')
    .documentElement.textContent
  return decodedString
}

const processCaption = (caption: string) => {
  const decodedText = decodeHTMLEntities(caption)

  // Split the text into segments based on URLs
  const urlRegex =
    /(https?:\/\/[a-zA-Z0-9-._~:/?#\[\]@!$&'()*+,;=]+[a-zA-Z0-9-_~/#[\]@$*+=])/g
  const segments = decodedText?.split(urlRegex)

  return segments?.map((segment, index) => {
    // Check if this segment is a URL
    if (segment.match(/^https?:\/\//)) {
      return (
        <a key={index} href={segment} target='_blank' rel='noopener noreferrer'>
          {segment}
        </a>
      )
    }
    return <span key={index}>{segment}</span>
  })
}

const ImageBlock = ({
  data,
}: {
  data: {
    file: { url: string; width: number; height: number }
    caption: string
  }
}) => (
  <figure>
    <Image
      className='w-full rounded-lg'
      alt={data.caption}
      src={data.file.url}
      width={data.file?.width || 512}
      height={data.file?.height || 512}
      layout='responsive'
    />
    {data.caption && (
      <figcaption className='text-sm'>
        {processCaption(data.caption)}
      </figcaption>
    )}
  </figure>
)

export default ImageBlock
