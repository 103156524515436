import { DocumentIcon, DownloadIcon } from '@heroicons/react/outline'
import { formatBytes } from 'lib/utils'
import { useTranslation } from 'next-i18next'

const AttachesBlock = ({
  data,
}: {
  data: {
    file: { url: string; name: string; size: number; extension: string }
    title: string
  }
}) => {
  return (
    <div className='border border-mischka rounded-lg p-3 my-5 text-black'>
      <div className='flex space-x-3 items-center'>
        <div className='pb-1'>
          <DocumentIcon className='w-6 h-6' />
        </div>
        <div className='flex-1'>
          <div>{data.title}</div>
          <div className='text-xs text-gray-500'>
            {formatBytes(data.file.size)}
          </div>
        </div>
        <div>
          <a
            target='_blank'
            href={data.file.url}
            className='px-2 py-1 -mr-2'
            rel='noreferrer'
          >
            <DownloadIcon className='w-6 h-6 inline-block' />
          </a>
        </div>
      </div>
    </div>
  )
}

export default AttachesBlock
