import { useMutation } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { Menu, Transition } from '@headlessui/react'
import { CalendarIcon } from '@heroicons/react/outline'
import { CalendarEvent, google, ics, office365, outlook } from 'calendar-link'
import classNames from 'classnames'
import Button from 'components/Button'
import dayjs from 'dayjs'
import { GET_COURSE_EDITION, GET_COURSE_STEP } from 'gql/courses'
import { RECORD_MEETING_ATTENDANCE } from 'gql/onlineMeeting'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { menuItemClasses, menuTransition } from 'styles/menu'
import { CourseEdition } from 'types/course'
import GoogleMeetLogo from '../../svg/google-meet.svg'
import TeamsLogo from '../../svg/teams.svg'
import ZoomLogo from '../../svg/zoom.svg'

const detectMeetingPlatform = (url: string) => {
  if (!url) return null

  const lowerUrl = url.toLowerCase()
  if (lowerUrl.includes('meet.google.com')) return 'google-meet'
  if (lowerUrl.includes('zoom.us')) return 'zoom'
  if (lowerUrl.includes('teams.microsoft.com')) return 'teams'

  return null
}

const getPlatformLogo = (platform: string | null) => {
  switch (platform) {
    case 'google-meet':
      return <GoogleMeetLogo className='w-14 h-14 sm:mb-3 -ml-1 mr-5' />
    case 'zoom':
      return <ZoomLogo className='w-14 h-14 sm:mb-3 -ml-1 mr-5' />
    case 'teams':
      return <TeamsLogo className='w-14 h-14 sm:mb-3 -ml-1 mr-5' />
    default:
      return <CalendarIcon className='w-14 h-14 sm:mb-3 -ml-1 mr-5' />
  }
}

const OnlineMeetingBlock = ({
  data,
}: {
  data: {
    url: string
    date: string
    my_response: boolean | null
    number_attending: number
    number_absent: number
    zoom_id: string
  }
}) => {
  const time = dayjs(data.date).format('h:mma')
  const date = dayjs(data.date).format('D MMM YYYY')
  const { t } = useTranslation()
  const router = useRouter()
  const zoomId = data.zoom_id
  const [going, setGoing] = useState<boolean | null>(data.my_response)
  const [numGoing, setNumGoing] = useState(data.number_attending)
  const [numNotGoing, setNumNotGoing] = useState(data.number_absent)

  const platform = detectMeetingPlatform(data.url)

  const [recordAttendance] = useMutation(RECORD_MEETING_ATTENDANCE, {
    update: (cache, res, { variables }) => {
      const data: any = cache.readQuery({
        query: GET_COURSE_STEP,
        variables: { ...router.query },
      })

      const contentJson = data.getCourseStep.contentJson
      const i = contentJson.blocks.findIndex(
        (x: any) => x.data.zoom_id === zoomId,
      )

      const blocks = [...contentJson.blocks]
      blocks[i] = {
        ...contentJson.blocks[i],
        data: {
          ...contentJson.blocks[i].data,
          my_response: variables?.attending,
          number_attending: res.data?.recordZoomAttendance,
        },
      }

      cache.writeQuery({
        query: GET_COURSE_STEP,
        variables: { ...router.query },
        data: {
          getCourseStep: {
            ...data.getCourseStep,
            contentJson: {
              ...data.getCourseStep.contentJson,
              blocks,
            },
          },
        },
      })
    },
  })

  const handleRecordAttendance = (value: boolean) => {
    const update = () => {
      setGoing(value)

      try {
        recordAttendance({
          variables: {
            zoomId,
            attending: value,
          },
        })
      } catch (e) {
        console.log(e)
      }
    }

    if (!going && value) {
      if (numGoing !== undefined) {
        setNumGoing(numGoing + 1)
      }
      if (numNotGoing !== undefined && going === false) {
        setNumNotGoing(numNotGoing - 1)
      }
      update()
    }
    if ((going || going === null) && !value) {
      if (numNotGoing !== undefined) {
        setNumNotGoing(numNotGoing + 1)
      }
      if (numGoing !== undefined && going === true) {
        setNumGoing(numGoing - 1)
      }
      update()
    }
  }

  const { data: editionData } = useQuery<{ getCourseEdition: CourseEdition }>(
    GET_COURSE_EDITION,
    {
      variables: { ...router.query },
    },
  )

  const event = {
    title: `Online meeting for ${editionData?.getCourseEdition.title}`,
    start: dayjs(data.date).format('YYYY-MM-DD HH:mm'),
    startTime: dayjs(data.date).format('YYYY-MM-DD HH:mm'),
    location: data.url,
    duration: [1, 'hour'],
  } as CalendarEvent

  const calLinks = [
    { name: t('iCal'), href: ics(event) },
    { name: t('Google'), href: google(event) },
    { name: t('Outlook'), href: outlook(event) },
    { name: t('Office 365'), href: office365(event) },
  ]

  return (
    <div className='border border-mischka rounded-lg my-5 flex flex-col lg:flex-row'>
      <div className='text-cinder bg-whisper lg:w-40 p-4 rounded-l-lg flex-shrink-0 flex flex-col items-center justify-center'>
        {getPlatformLogo(platform)}
        <div className='leading-none whitespace-nowrap'>
          <span className='font-bold text-sm'>{time}</span>
          <br />
          <span className='text-xs'>{date}</span>
        </div>
      </div>

      <div className='min-w-0 px-5 py-5'>
        <div className='text-gray-700 font-semibold mb-2' data-testid='title'>
          {t('Online meeting')}
        </div>

        {data.url && (
          <>
            <p className='text-gray-700 font-normal !mt-0'>
              {t('You can join your meeting using the button.')}
            </p>
            <a
              href={data.url}
              target='_blank'
              rel='noreferrer'
              className='!no-underline mr-4'
              data-testid='anchor'
            >
              <Button>{t('Join')}</Button>
            </a>
          </>
        )}

        <Menu as='div' className='inline-block relative mr-4'>
          <div>
            <Menu.Button as='div'>
              <Button className='bg-cinder'>{t('Add to calendar')}</Button>
            </Menu.Button>
          </div>
          <Transition {...menuTransition}>
            {editionData && (
              <Menu.Items className='origin-top-left absolute left-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-30'>
                {calLinks.map((calLink) => (
                  <Menu.Item key={calLink.name}>
                    {({ active }) => (
                      <div>
                        <a
                          href={calLink.href}
                          target='_blank'
                          rel='noreferrer'
                          className={classNames(
                            menuItemClasses(active),
                            '!no-underline',
                          )}
                          download
                        >
                          {calLink.name}
                        </a>
                      </div>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            )}
          </Transition>
        </Menu>

        {data.url && (
          <div className='mt-4 space-x-3 sm:inline-block'>
            <span className='text-sm'>{t('Attending?')}</span>
            <span>
              <Button
                size='small'
                theme='secondary'
                className={classNames(
                  going === true ? 'text-purple-heart bg-indigo-100' : '',
                  'rounded-r-none text-sm focus:relative',
                )}
                onClick={() => handleRecordAttendance(true)}
              >
                {t('Yes')} {numGoing !== undefined && <>({numGoing})</>}
              </Button>
              <Button
                size='small'
                theme='secondary'
                onClick={() => handleRecordAttendance(false)}
                className={classNames(
                  going === false ? 'text-purple-heart bg-indigo-100' : '',
                  'rounded-l-none text-sm -ml-px',
                )}
              >
                {t('No')} {numNotGoing !== undefined && <>({numNotGoing})</>}
              </Button>
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export default OnlineMeetingBlock
