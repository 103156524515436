import gql from 'graphql-tag'

export type VideoAnalyticsAction = 'VIEW' | 'PLAYING' | 'PLAYED'

export type LogAnalyticsArguments = {
  mediaId?: string | number
  mediaUrl?: string
  action: VideoAnalyticsAction
  additionalData: object
}

export const LOG_ANALYTICS_EVENT = gql`
  mutation logVideoAnalytics(
    $mediaId: ID
    $mediaUrl: String!
    $action: VideoAnalyticsAction!
    $additionalData: JSON!
  ) {
    logVideoAnalytics(
      mediaId: $mediaId
      mediaUrl: $mediaUrl
      action: $action
      additionalData: $additionalData
    )
  }
`
