import LinkBlock from 'components/LinkBlock/LinkBlock'
import VideoBlock from 'components/VideoBlock/VideoBlock'
import { Source } from 'types/source'
import { Subtitle } from 'types/subtitle'

type LinkToolBlockProps = {
  data: {
    link: string
    media?: boolean
    meta: {
      provider?: string
      media_id?: string | number
      description: string
      image: {
        url: string
      }
      title: string
    }
    poster: string
    subtitles?: [Subtitle]
    sources: [Source]
  }
}

const LinkToolBlock = ({ data }: LinkToolBlockProps) => {
  const {
    meta: {
      title,
      description,
      media_id,
      image: { url: imageUrl } = {},
    },
    link,
    subtitles,
    sources,
    media,
    poster,
  } = data

  if (media) {
    return (
      <VideoBlock
        sources={sources}
        subtitles={subtitles}
        poster={poster}
        mediaUrl={link}
        mediaId={media_id}
      />
    )
  }

  return (
    <LinkBlock
      url={link}
      title={title}
      desc={description}
      imageUrl={imageUrl}
    />
  )
}

export default LinkToolBlock
