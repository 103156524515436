import Plyr from 'plyr-react'
import 'plyr-react/dist/plyr.css'
import { useApolloClient } from '@apollo/client'
import {
  LOG_ANALYTICS_EVENT,
  LogAnalyticsArguments,
  VideoAnalyticsAction,
} from 'gql/analytics'
import { useSession } from 'next-auth/client'
import { useRef } from 'react'
import { Source } from 'types/source'
import { Subtitle } from 'types/subtitle'

const percentageDurationAsComplete = 80 // percentage duration that we consider as video has been watched

type VideoBlockProps = {
  poster: string
  sources: [Source]
  subtitles?: [Subtitle]
  mediaId?: string | number
  mediaUrl?: string
}

const VideoBlock = ({
  sources,
  subtitles,
  poster,
  mediaId,
  mediaUrl,
}: VideoBlockProps) => {
  const client = useApolloClient()
  const [session, sessionLoading] = useSession()
  const ref = useRef<Plyr>()

  // Use a ref to track completed events
  const logged = useRef({
    VIEW: false,
    PLAYING: false,
    PLAYED: false,
  })

  const logEvent = (action: VideoAnalyticsAction) => {
    // Ensure we only log any event once
    if (logged.current[action]) {
      return
    }
    logged.current[action] = true

    // grab events
    const additionalData = {
      device: 'web',
      source: 'remote',
      subtitle_language: ref.current?.language,
      language_per_user: document.documentElement.lang,
      referrer: window.document.referrer,
      current_url: window.location.toString(),
    }

    // Mutate directly without affecting state change (causes reload of component)
    return client.mutate<boolean, LogAnalyticsArguments>({
      mutation: LOG_ANALYTICS_EVENT,
      variables: {
        mediaId,
        mediaUrl,
        action,
        additionalData,
      },
    })
  }

  const videoSource = {
    type: 'video',
    poster: poster,
    sources: sources,
    tracks: subtitles,
  } as Plyr.SourceInfo

  return (
    <div className='w-full rounded-lg overflow-hidden'>
      <Plyr
        crossOrigin='anonymous'
        source={videoSource}
        ref={(player) => {
          const plyr = player?.plyr as undefined | { on: undefined } | Plyr
          if (plyr?.on) {
            ref.current = plyr
            plyr.on('ready', () => logEvent('VIEW'))
            plyr.on('play', () => logEvent('PLAYING'))
            plyr.on('timeupdate', () => {
              const currentTime = plyr.currentTime
              const percentageComplete = Math.round(
                (currentTime / plyr.duration) * 100,
              )
              if (percentageComplete >= percentageDurationAsComplete) {
                logEvent('PLAYED')
              }
            })
          }
        }}
      />
    </div>
  )
}

export default VideoBlock
