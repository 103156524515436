type IframelyInfoBlockProps = {
  data: {
    link: string
    iframelyInfo?: any
  }
}

const IframelyInfoBlock = ({ data }: IframelyInfoBlockProps) => {
  const { iframelyInfo } = data

  return <div dangerouslySetInnerHTML={{ __html: iframelyInfo.html }} />
}

export default IframelyInfoBlock
